import { LabeledSelect, LabeledInput, Loading, LabeledCheckbox} from "@commonsku/styles";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOptions } from '../components/company-search/selectors';
import { createTargetsMngmnt, fetchTargetsMngmnt, updateTargetsMngmnt, updateShowTargetsTenant} from "../actions";
import { dateStr } from "../utils";
import { consecutive_years, MNGMNT_TARGETS_POPUP, month_names,formatTargetMoney } from "./AnalyticsHelper";
import { getUsersList} from "../selectors/users";

export function SetTargetTabledWindow (props) {
    const {role,filters} =  props;
    const date = useMemo(() => new Date(), []);
    const show_invoice_targets = 'show_invoice_targets';
    const show_sales_order_targets = 'show_sales_order_targets';
    const reps = getOptions(useSelector(getUsersList),
        'user_id',
        (v) => (v.contact_first_name + ' ' + v.contact_last_name),
        false);
    const identity = useSelector(s=>s.identity);
    const logged_user_id = identity.user_id;
    const tenant_id = identity.tenant_id;
    const [rep,setRep]= useState( role === 'sales' ? reps.filter(r=>r.value == logged_user_id)[0] : {label:'All Reps', value:''});
    const [year,setYear] = useState({label : date.getFullYear(), value : date.getFullYear()});
    const [showInvoices, setShowInvoices] = useState(identity.show_invoice_targets == true ? true : false );
    const [showSO,setShowSO] = useState(identity.show_sales_order_targets == true ? true : false);
    const dispatch = useDispatch();

    const onChangeRep = (value)=>{
        setRep(value);
        const yr = year.value ? year.value : date.getFullYear();
        dispatch(fetchTargetsMngmnt({
            rep_id : value.value,
            start_stamp: dateStr(new Date(yr,0,1)),
            end_stamp: dateStr(new Date(yr+1,0,1))
        }));
    };

    const onChangeYear = (value) => {
        setYear(value);
        dispatch(fetchTargetsMngmnt({
            rep_id: rep.value,
            start_stamp:dateStr(new Date(value.value,0,1)),
            end_stamp:dateStr(new Date(value.value+1,0,1))
        }));
    };

    const handleTargetUpdate = (row,e) => {
        const id = row.target_id;
        const input_value = Math.abs(e.target.value.replaceAll(',',''));
        if ( id === 0) {
            dispatch(createTargetsMngmnt({
                rep_id:rep.value,
                target_value : input_value,
                target_type:e.target.name,
                month: month_names.indexOf(row.month)+1,
                year:year.value,
            }));
        } else {
          e.target.name === 'sales_target_value' ? dispatch(updateTargetsMngmnt(row.target_id,input_value,null,{filters:JSON.stringify(filters)})) :
          dispatch(updateTargetsMngmnt(row.target_id,null,input_value,{filters:JSON.stringify(filters)}));
        }

    };

    const handleToggleTargetUpdate = (type,value) => {
        type === show_invoice_targets ? setShowInvoices(!value) : setShowSO(!value);
        dispatch(updateShowTargetsTenant(tenant_id,type,!value));

    };

    const targets_management = useSelector((s)=>s.entities.targets_management);
    const loading = useSelector((s)=>s.entities.loading_analytics_popup[MNGMNT_TARGETS_POPUP]);
    const [targets, setTargets] = useState([]);

    useEffect(() => {
        setTargets(targets_management);
      }, [targets_management]);

    useEffect(()=>{
        dispatch(fetchTargetsMngmnt({
        rep_id : role === 'sales' ? reps.filter((r)=>r.value === logged_user_id)[0].value : '',
        start_stamp: dateStr(new Date(date.getFullYear(),0,1)),
        end_stamp: dateStr(new Date(date.getFullYear()+1,0,1))
    }));},[]); // eslint-disable-line
    return(

        <div className ='set-target-div'>
            <table className ="table table-striped">
                <thead>
                </thead>
                <tbody style={{border: 'none'}}>
                    { role === 'sales' ? null :
                    <tr className="disable-hover">
                        <td style={{backgroundColor:'#EDF4F7', width:'28%', color:'#2A4D63'}}>Show on dashboard</td>
                        <td colSpan={2} style={{textAlign : 'center'}}>
                        <LabeledCheckbox  labelStyle={{marginRight:0}}  checked = {showInvoices} onChange = {e=> handleToggleTargetUpdate(show_invoice_targets,showInvoices)}/>
                        </td>
                        <td  colSpan={2} style={{textAlign:'center'}}>
                        <LabeledCheckbox  checked= {showSO} onChange = {e=>handleToggleTargetUpdate(show_sales_order_targets,showSO)} />
                        </td>
                    </tr>}
                    <tr style={{color:'#2A4D63'}} className="disable-hover">
                        <td style={{backgroundColor:'#EDF4F7', width:'28%'}}>
                        <LabeledSelect
                        isDisabled={role === 'sales' ? true : false}
                        style={{color:'#2A4D63'}}
                        name='rep_user'
                        options= {[{label:'All Reps', value:''}].concat(reps)}
                        value = {rep}
                        onChange= {(e)=> onChangeRep(e)} />
                        </td>
                        <td colSpan={2} style={{ fontWeight:'bold', textAlign: 'center'}}>Invoices</td>
                        <td colSpan={2} style={{fontWeight:'bold', textAlign: 'center'}}>Sales Orders in Production</td>
                    </tr>
                    <tr style={{color:'#2A4D63'}} className="disable-hover">
                        <td  style={{backgroundColor:'#EDF4F7', width:'28%'}}>
                        <LabeledSelect
                        name = 'year'
                        options = {consecutive_years}
                        value= {year}
                        onChange={(e)=>onChangeYear(e)} />
                        </td>
                        <td style={{ textAlign: "center"}} >Target</td>
                        <td style={{ textAlign: "center"}}>Last Year <br/>(actual)</td>
                        <td  style={{textAlign:"center"}} >Target</td>
                        <td style={{textAlign:"center"}} >Last Year <br/>(actual)</td>
                    </tr>
                    {loading ?
                    month_names.map((m,index) =>
                    <tr key={index} style={{color:'#2A4D63'}}>
                        <td style={{padding:'0rem 0rem 1rem 0.6rem', backgroundColor:'#EDF4F7'}}>{m}</td>
                        {index === 0 ?
                        <td rowSpan={6} colSpan={4}>
                            <Loading />
                            <p style={{
                                padding: 10,
                                margin: 0,
                                textAlign: 'center',
                                color: '#00A0B6',
                                fontSize: '1.3rem',
                            }} >Loading...
                            </p>
                        </td> : <></>}
                    </tr>) :
                    <>
                    {targets?.map((x,index)=>
                    <tr style={{color:'#2A4D63'}}>
                         <td style={{padding:'2px 8px', backgroundColor:'#EDF4F7'}}>{x.month}</td>
                         <td  style={{padding:'2px 8px'}}>
                        <LabeledInput
                        noMargin
                        disabled={rep.value === '' || role === 'sales' ? true : false}
                        style={{color: rep.value === '' || role === 'sales' ? '#899CA9' :'#2A4D63'}}
                        name='sales_target_value'
                        value = {x.invoice_target}
                        onChange={e => {
                            const value = e.target.value;
                            setTargets(s => (s.map((t, i) => i === index ? { ...t, invoice_target: value } : t)))
                        }}
                        onBlur = {e=>handleTargetUpdate(x,e)}
                        onFocus= {e=>e.target.select()}/>
                        </td>
                        <td  style={{padding:'0rem 1rem 0rem 0.3rem'}}>
                        <LabeledInput
                        noMargin
                        disabled
                        readOnly
                        value = {formatTargetMoney(x.invoice_value)} />
                        </td>
                        <td style={{padding:'0rem 0rem 0rem 1rem'}}>
                        <LabeledInput
                        noMargin
                        disabled={rep.value === '' || role === 'sales' ? true : false}
                        style={{color: rep.value === '' || role === 'sales' ? '#899CA9' :'#2A4D63'}}
                        name='in_production_target_value'
                        value = {x.sales_orders_target}
                        onChange={e => {
                          const sales_orders_target = e.target.value;
                          setTargets(
                            s=> s.map((o,i) => i === index ? {...o, sales_orders_target} : o)
                          )
                        }}
                        onBlur={e=>handleTargetUpdate(x,e)}
                        onFocus={e=>e.target.select()} />
                        </td>
                        <td style={{padding:'0rem 1.6rem 0rem 0.3rem'}}>
                        <LabeledInput
                        noMargin
                        disabled
                        readOnly
                        value = {formatTargetMoney(x.sales_orders_value)} />
                        </td>
                    </tr>)}
                    <tr>
                    <td style={{padding:'0rem 0rem 0rem 8px', backgroundColor:'#EDF4F7'}}><b>Total</b></td>
                    <td  style={{padding:'4px 8px'}}>
                        <LabeledInput
                        noMargin
                        disabled
                        name='sales_target_total'
                        readOnly
                        value = {formatTargetMoney(targets?.reduce((prev,current)=>{return ( (parseInt((current.invoice_target).replaceAll(",","")) || 0) + prev);},0))}/>
                        </td>
                        <td style={{padding:'0rem 1rem 0rem 0.3rem'}}>
                        <LabeledInput
                        noMargin
                        disabled
                        readOnly
                        value = {formatTargetMoney(targets?.reduce((prev,current)=>{return parseInt(current.invoice_value) + prev;},0))} />
                        </td>
                        <td style={{padding:'0rem 0rem 0rem 1rem'}}>
                        <LabeledInput
                        noMargin
                        disabled
                        readOnly
                        name='in_production_total'
                        value = {formatTargetMoney(targets?.reduce((prev,current)=>{return ((parseInt((current.sales_orders_target).replaceAll(",","")) || 0) + prev);},0))} />
                        </td>
                        <td style={{padding:'0rem 1.6rem 0rem 0.3rem'}}>
                        <LabeledInput
                        noMargin
                        disabled
                        readOnly
                        value = {formatTargetMoney(targets?.reduce((prev,current)=>{return parseInt(current.sales_orders_value) + prev;},0))} />
                        </td>
                    </tr>
                    </>}
                </tbody>
            </table>
        </div>

    );

}
