import { Chart } from '../components/helpers';
import {createDonutChartSales} from '../components/dashboard/utils/chart_utils';
import {  Col, Row} from '@commonsku/styles';

function SalesAndMarginByClient(props) {
    const {sales_by_client_data,margin_by_client_data} = props.chart_data;
    const{selectedTab,onClickClientSlice} = props;
    return(
        <Row id="Client" >
            <Col xl={6} sm={12} >
                <Chart
                id="sales_this_month_chart"
                type="pie"
                height="340px"
                width="100%"
                isExportable={false}
                data={ selectedTab === 'Sales' ? (sales_by_client_data.this_month_data.length > 0 ? sales_by_client_data.this_month_data :
                [{ total:0,client_name:'No client yet'}]) :
                (margin_by_client_data.this_month_margin.length > 0 ? margin_by_client_data.this_month_margin :
                [{ total:0,  client_name:'No client yet'}])}
                onChartReady={(chart, data) => createDonutChartSales(chart, {
                onClickSlice : onClickClientSlice,
                data,
                formatNumber:'#,###.',
                tooltipText:"{category}: ${value}",
                isExportable:false,
                value: 'total',
                category: 'client_name',
                radius: '65',
                labelText: "[font-size:16px]This Month[/]\n[bold]${values.value.sum}[/]",
                })}
                />
            </Col>
            <Col xl={6} sm={12}>
                <Chart
                id="sales_last_month_chart"
                type="pie"
                height="340px"
                width="100%"
                isExportable={false}
                data={ selectedTab === 'Sales' ? (sales_by_client_data.last_month_data.length > 0 ? sales_by_client_data.last_month_data :
                [{total:0,client_name:'No client yet'}]):
                (margin_by_client_data.last_month_margin.length > 0 ?  margin_by_client_data.last_month_margin :
                [{ total:0, client_name:'No client yet'}]) }
                onChartReady={(chart_m, data) => createDonutChartSales(chart_m, {
                data,
                formatNumber:'#,###.',
                tooltipText:"{category}: ${value}",
                onClickSlice : onClickClientSlice,
                isExportable:false,
                value: 'total',
                category: 'client_name',
                radius: '65',
                labelText: "[font-size:16px]Last Month[/]\n[bold]${values.value.sum}[/]"
                })}
                />

            </Col>
        </Row>
        );
}

export default SalesAndMarginByClient;