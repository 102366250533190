import { Chart } from '../components/helpers';
import {createBarChart} from '../components/dashboard/utils/chart_utils';

function SalesActivityChart(props) {
    return (
        <Chart style = {{ display : props.selectedTab === 'SalesActivity' ? 'block' : 'none'}}
        type="Bar"
        height="345px"
        width="100%"
        isExportable={false}
        data ={props.chart_data}
        onChartReady={(chart,data)=>createBarChart(chart,{
            onClickColumn:props.onClickChartColumn ? props.onClickChartColumn : null,
            isExportable:false,
            data,
            cursorLineX:false,
            cursorLineY:false,
            isLegendCheckbox:true,
            formatNumber:'#a',
            chartColorsAnalytics:['#28D5EE','#00A0B6','#00788A'],
            xAxes: [{
            cursorTooltipEnabled:false,
            category:'time',
            title: 'Time',
            showGrid: false,
            showAxis: true,
            showTitle: true,
            showLabel: true,
            disableGridTemplate:true
            }],
            yAxes: [{
            cursorTooltipEnabled: false,
            showGrid: false,
            showAxis: false,
            showTitle: false,
            disableGridTemplate:true,
            showLabel:true,
            renderOutside:true
            }], series: [ {
            categoryX: 'time',
            valueY: 'ESTIMATE',
            name: "Estimates",
            tooltipText: `{name}: {valueY}`,
            strokeWidth: 0,
            fillPropertyOpacity: 'opacity',
            fillColor : '#28D5EE',
            stroke : '#28D5EE',
            stacked:true

            },{
            categoryX: 'time',
            valueY: 'PRESENTATION',
            name: "Presentations",
            valueYShow : "totalPercent",
            tooltipText: `{name}: {valueY}`,
            strokeWidth: 0,
            fillPropertyOpacity: 'opacity',
            fillColor : '#00A0B6',
            stroke : '#00A0B6',
            stacked:true
            },{
            categoryX: 'time',
            valueY: 'OPPORTUNITY',
            name: "Opportunities",
            tooltipText: `{name}: {valueY}`,
            strokeWidth: 0,
            fillPropertyOpacity: 'opacity',
            fillColor : '#00788A',
            stroke : '#00788A',
            stacked:true
            },
        ]
        })}
        >
        </Chart>
    );
}

export default SalesActivityChart;
