
import { Chart } from '../components/helpers';
import {calcChartHeight} from '../components/dashboard/utils';
import {createBulletImageBarChart} from '../components/dashboard/utils/chart_utils';

function SalesOrdersPerRepChart(props) {
    const {data,barColor,onClickChartColumn} = props;
    return (
        <div style={{height:'500px',overflowY: data.length >= 7 ? 'scroll' : 'hidden'}}>
        <Chart
        type="Bar"
        height={calcChartHeight(data.length <= 3 ? 3 : data.length + 1, 1, 20*70, 20) + "px"}
        width="100%"
        isExportable={false}
        data = {data}
        onChartReady={(chart,data)=>createBulletImageBarChart(chart,{
            data,
            onClickColumn : onClickChartColumn ? onClickChartColumn : null,
            valueX: 'total',
            valueY: 'rep_full_name',
            imgField: 'img_src',
            imgHeight: 50,
            imgWidth: 50,
            barHeight:50,
            crnerRad1: 0,
            crnerRad2: 40,
            circleRadius:25,
            outlineCircle:false,
            hideValueAxis:true,
            isExportable:false,
            cursorLineX:false,
            cursorLineY:false,
            formatNumber:'#,###.',
            chartColorsAnalytics:barColor,
            hideCategoryAxisLabel: true,
            insideBarLabelText: "[bold]{rep_full_name}[/]\u00A0\u00A0\u00A0\${valueX}",  
            hideZoomOutBtn: true,
            tooltipText:''
        })}
        >
        </Chart>
        </div>

    );
}

export default SalesOrdersPerRepChart;
