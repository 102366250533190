import { Chart } from '../components/helpers';
import {createBarChart} from '../components/dashboard/utils/chart_utils';
import WidgetCardBox, { WidgetTitle } from '../components/dashboard/WidgetCardBox';
import {  Col, Row} from '@commonsku/styles';
import { useSelector } from 'react-redux';

function InvoicesVsTargetChart(props) {
    const identity = useSelector(s=>s.identity);
    return (
        <WidgetCardBox colSizes={{xs: 12, sm:12, lg: 6}}
        components={{
        Title: <WidgetTitle startText={'Invoices vs. Target'} />
        }}>
            <Col xs>
                <Chart
                type="Bar"
                height = {props.chart_height ? props.chart_height : '340px'}
                width="100%"
                isExportable={false}
                data={props.chart_data}
                onChartReady={(chart, data) => createBarChart(chart, {
                data,
                onClickColumn:props.onClickChartColumn ? props.onClickChartColumn : null,
                isExportable:false,
                cursorLineX:false,
                cursorLineY:false,
                isLegendCheckbox:true,
                formatNumber:'#a',
                chartColorsAnalytics:['#B8C4CB','#00D374','#DB0057'],
                toggleInvoiceLegend:true,
                xAxes: [{
                cursorTooltipEnabled:false,
                category:'month',
                title: 'Month',
                showGrid: true,
                showAxis: true,
                showTitle: true,
                showLabel: true,
                disableGridTemplate:true
                }],
                yAxes: [{
                cursorTooltipEnabled: false,
                showLabel: true,
                renderOutside:true,
                showGrid: true,
                showAxis: false,
                showTitle: false,
                disableGridTemplate:true
                }],
                series: [{
                categoryX: 'month',
                valueY: 'total',
                name: "Previous Year",
                tooltipText: `[#fff font-size: 15px]{month} {year.formatNumber('####')}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]`,
                strokeWidth: 0,
                fillPropertyOpacity: 'opacity',
                fillColor : '#B8C4CB', // green fill
                stroke : '#B8C4CB',
                },
                {
                    categoryX: 'month',
                    valueY: 'total_next_year' ,
                    name: 'This Year',
                    tooltipText: `[#fff font-size: 15px]{month} {next_year.formatNumber('####')}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]`,
                    strokeWidth: 0,
                    fillPropertyOpacity: 'opacity',
                    fillColor : '#00D374', // yellow fill
                    stroke : '#00D374'
                }
            ],
                lineSeries:  [{
                categoryX: 'month',
                valueY: 'target',
                name: "Target",
                tooltipText: `[#fff font-size: 15px]{categoryX} {target_year.formatNumber('####')}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]`,
                strokeWidth: 3,
                stroke:'#DB0057',
                fillPropertyOpacity: 'opacity',
                }]
                })}
                >
                </Chart>
            </Col>
        </WidgetCardBox>
    );
}

export default InvoicesVsTargetChart;
