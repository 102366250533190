import React, { Component } from 'react';
import { startCase, toLower } from 'lodash';
import Header from './Header';
import MainSection from './MainSection';
import { Chart } from '../components/helpers';
import { createDonutChartSales,createBarChart,createHorizontalBarChart, createBulletImageBarChart} from '../components/dashboard/utils/chart_utils';
import WidgetCardBox, { WidgetTitle } from '../components/dashboard/WidgetCardBox';
import { Theme, Col, Loading, Row, LabeledSelect,TabBar,Tab, Popup, Button} from '@commonsku/styles';
import { connect } from 'react-redux';
import { getOptions } from '../components/company-search/selectors';
import { createLoadClientList } from '../actions/client';
import { createSelector } from 'reselect';
import { getClientDropdown, getTeamDropdown } from '../selectors/dropdowns';
import {createAllLoadStatuses, updateMngmntAnalyticsView, updateMngmntChart} from '../actions';
import { getCommonskuStyleDropdownOptions ,_createEventHandler,dateStr, getIdentityUtils} from '../utils';
import { createLoadAccountStatuses,fetchMngmntAnalytics,createLoadTeamList} from '../actions';
import DashboardPageTitle from '../components/dashboard/DashboardPageTitle';
import ProjectsTableWindowed from '../components/dashboard/ProjectsTableWindowed';
import {fetchAnalyticsReport} from '../actions';
import { GlobalStyle } from './DashboardApp';
import SalesOrdersInProductionChart from './SalesOrdersInProdChart';
import  InvoicesVsTargetChart  from './InvoicesVsTargetChart';
import SalesActivityChart from './SalesActivityChart';
import CRMActivityChart from './CRMActivityChart';
import SalesAndMarginByClient from './SalesAndMarginByClientChart';
import { ANALYTICS_REPORT_TYPES, CHART_TYPE, getCombinedTargetData, getTimeStamp, month_names, sales_by_rep_time_options } from './AnalyticsHelper';
import withReducers from '../store/withReducers';
import statusReducer from '../redux/status';
import notesReducer from '../redux/notes';
import SalesOrdersPerRepChart from './SalesOrdersPerRepChart';
import { NewProjectPopupButton } from '../components/project/NewProjectPopup';
import { SetTargetTabledWindow } from './SetTargetTabledWindow';

const getClientOptions = createSelector(getClientDropdown,
    values => values.filter(v => v.client_name).map(v => ({ value: v.client_id, label: v.client_name }))
  );

class ManagementAnalyticsApp extends Component {
    state={
        selectedTab:'Sales',
        selectedPerRepTab:'SO',
        selectedActivityTab:'CRM',
        filters: {
          repType:{
            value:'client',label:'Client Rep'
          },
          repUser:{
            value:'',label:'All'
          },
          client:{
            value:'', label:'All'
          },
          clientStatus :{
            value:'', label:'All'
          },
          lastYear:false
        },
        showPopup:null,
        reloadReport:false,
        SO_BY_REP_FILTER : {
          value: 'month',
          label:'Month',
        },
        CHART_BY_REP_TITLE :{
          current :month_names[new Date().getMonth()],
          previous: month_names[new Date().getMonth()-1]
        }

    }
    componentDidMount() {
        const { onLoadStatuses,onLoadClients,onLoadAnalyticsData,onLoadTeams, onLoadAccountStatuses} = this.props;
        onLoadAnalyticsData();
        onLoadStatuses();
        onLoadClients();
        onLoadTeams();
        onLoadAccountStatuses();
    }
    onClickClientSlice =  (data) => {
      if (data.sliceGrouperOther === true) {
        return;
      }
      const {fetchPopUpData} = this.props;
      const {selectedTab,filters} = this.state;
      const report_type = selectedTab === 'Sales' ? ANALYTICS_REPORT_TYPES.SALES_BY_CLIENT.type.mngmnt : ANALYTICS_REPORT_TYPES.MARGIN_BY_CLIENT.type.mngmnt;
      const route = selectedTab === 'Sales' ? ANALYTICS_REPORT_TYPES.SALES_BY_CLIENT.route : ANALYTICS_REPORT_TYPES.MARGIN_BY_CLIENT.route;
      const month_name = month_names[parseInt(data.month)-1];
      const title = data.client_name + " - " + month_name;
      const start_of_month =  dateStr(new Date(parseInt(data.year),parseInt(data.month)-1,1));
      const end_of_month =  dateStr(new Date(parseInt(data.year),parseInt(data.month),1));
      const subTitle =  selectedTab === 'Sales' ? ANALYTICS_REPORT_TYPES.SALES_BY_CLIENT.title  : ANALYTICS_REPORT_TYPES.MARGIN_BY_CLIENT.title;
      fetchPopUpData(route,report_type,{
        client_id:data.client_id,
        start_stamp:start_of_month,
        end_stamp: end_of_month,
        filters:JSON.stringify(filters)
      });
      this.setState({
        showPopup : {
          type: 'projects-list',
          title: subTitle + title,
          report_type: report_type,
        }
      });
    }
    onClickInvoiceTargetChartColumn = (data, year_type = null) => {
      const {fetchPopUpData} = this.props;
      const report_type = ANALYTICS_REPORT_TYPES.INVOICES_VS_TARGET.type.mngmnt;
      const route = ANALYTICS_REPORT_TYPES.INVOICES_VS_TARGET.route;
      const yr = year_type === 'total_next_year' ? data.next_year : data.year;
      const title = data.month + ' ' + yr;
      const month_number= new Date(`${data.month} 1 ${yr}`).getMonth()+1;
      const end_of_month = dateStr(new Date(yr, month_number, 1));
      const start_of_month =  dateStr(new Date(yr, month_number - 1, 1));
      fetchPopUpData(route, report_type, {
        start_stamp: start_of_month,
        end_stamp: end_of_month,
        filters : JSON.stringify(this.state.filters)
      });
      this.setState({
        showPopup: {
        type: 'projects-list',
        title: ANALYTICS_REPORT_TYPES.INVOICES_VS_TARGET.title + title,
        report_type: report_type,
        },
    });
    };
    onClickSalesActivityChartColumn = (data,order_type=null) => {
      const {fetchPopUpData} = this.props;
      const report_type = ANALYTICS_REPORT_TYPES.SALES_ACTIVITY.type.mngmnt;
      const route = ANALYTICS_REPORT_TYPES.SALES_ACTIVITY.route;
      const title = data.time;
      const month_number = (new Date(`${data.month} 1 ${data.year}`)).getMonth() + 1;
      const end_of_month = dateStr(new Date(data.year, month_number, 1));
      const start_of_month =  dateStr(new Date(data.year, month_number - 1, 1));
      fetchPopUpData(route, report_type, {
        order_type: order_type,
        start_stamp: start_of_month,
        end_stamp:end_of_month,
        filters : JSON.stringify(this.state.filters)
      });
      this.setState({
        showPopup: {
        type: 'projects-list',
        title: ANALYTICS_REPORT_TYPES.SALES_ACTIVITY.title + title,
        report_type: report_type,
        order_type: order_type,
        },
    });
    };
    onClickSalesOrdersInProductionChartColumn = (data,year_type=null) => {
      const {fetchPopUpData} = this.props;
      const report_type = ANALYTICS_REPORT_TYPES.SALES_ORDERS_IN_PRODUCTION.type.mngmnt;
      const route = ANALYTICS_REPORT_TYPES.SALES_ORDERS_IN_PRODUCTION.route;
      const yr = year_type === 'total_next_year' ? data.next_year : data.year;
      const title = data.month + ' ' + yr;
      const month_number= new Date(`${data.month} 1 ${yr}`).getMonth()+1;
      const end_of_month = dateStr(new Date(yr, month_number, 1));
      const start_of_month =  dateStr(new Date(yr, month_number-1, 1));
      fetchPopUpData(route, report_type, {
        start_stamp: start_of_month,
        end_stamp:end_of_month,
        filters : JSON.stringify(this.state.filters)
      });
      this.setState({
        showPopup: {
        type: 'projects-list',
        title: ANALYTICS_REPORT_TYPES.SALES_ORDERS_IN_PRODUCTION.title + title,
        report_type: report_type,
        },
    });
    }
    onClickRepChartBar = (data) => {
      const {fetchPopUpData} = this.props;
      const report_type = ANALYTICS_REPORT_TYPES.CHART_BY_REP.type.mngmnt;
      const route = ANALYTICS_REPORT_TYPES.CHART_BY_REP.route;
      const time_stamp = getTimeStamp(data.time_division_code);
      const title = startCase(toLower(data.order_type))+'s' + ' - ' + data.rep_full_name + ' - ' +  time_stamp.name;
      fetchPopUpData(route, report_type, {
        start_stamp: time_stamp.startStamp,
        end_stamp:time_stamp.endStamp,
        rep_id: data.rep_user_id,
        order_type:data.order_type,
        filters : JSON.stringify(this.state.filters)
      });
      this.setState({
        showPopup: {
        type: 'projects-list',
        title: title,
        report_type: report_type,
        },
    });
    }
    onChangeFilter(value,filter) {
    const { onChangeFilterDrop} = this.props;
    if (value === null && filter !== 'teamId') {
      value = {label: 'All', value: ''};
    }
    if (filter === 'repUser' && value.value !== 'GROUP') {
      this.setState({filters: {...this.state.filters,[filter]:value,'teamId':{key:'',value:''}}},() => {
        onChangeFilterDrop(this.state.filters);
      });
    } else {
    this.setState({filters: {...this.state.filters,[filter]:value}},() => {
      onChangeFilterDrop(this.state.filters);
    });
    }
    }
    onChangeTimeFilter(value, filter) {
      const {onChangeChartFilterMngmnt} = this.props;
      const timeStamp = getTimeStamp(value.value);
      const time_frame =  value.value.toLowerCase();
      const v = CHART_TYPE[filter];
      const route = v.route;
      this.setState({
        [filter]:value,
        filters: {...this.state.filters,
          soByRepTimeFilter:{
            so_by_rep_start_stamp: timeStamp.startStamp,
            so_by_rep_end_stamp: timeStamp.endStamp,
            so_by_rep_time_frame:time_frame
          }
        },
        CHART_BY_REP_TITLE:{
          current:timeStamp.current_name,
          previous: timeStamp.previous_name
        }
      });
      onChangeChartFilterMngmnt(route,{
        filters: JSON.stringify(this.state.filters),
        start_stamp:timeStamp.startStamp,
        end_stamp: timeStamp.endStamp,
        time_frame:time_frame
      });
    }
    renderPopup() {
      if (!this.state.showPopup) {
        return null;
      }
      return (<Popup
        title={this.state.showPopup.title}
        onClose={() => {
         this.setState({showPopup:false});
        }}
        className={`popup ${this.state.showPopup.type}-analytics-popup`}
      >   {(this.state.showPopup.type === 'projects-list' ?
      <ProjectsTableWindowed
        {...this.state.showPopup}
        onSave={() => this.setState({showPopup:false})}
       loadReportTotal={reportType =>  this.setState({reportType:reportType})}
      />
    : null)}
        <br />
      </Popup>
      );}
    targetPopup() {
      if (!this.state.showTargetPopup) {
        return null;
      }
      return (
      <Popup   style={{maxWidth: '850px', height: '96%', maxHeight: 'none'}}
      className={'set-target-analytics-popup'}
      title = {this.state.showTargetPopup.title}
      onClose= {() => {this.setState(({showTargetPopup:false}));
      }}
      ><SetTargetTabledWindow role='management' filters={this.state.filters}/></Popup>
      );}
    render() {
        const {popups,filterOptions,invoice_data,sales_by_client_data,sales_orders_target_data,
            margin_by_client_data,sales_activity_data,sales_pipeline_data, shop_by_invoices_data,invoice_target_data,managemnt_data,
            sales_orders_per_rep_data, invoice_per_rep_data, identity,sales_orders_data} =this.props;
            const {filters,selectedTab,SO_BY_REP_FILTER,CHART_BY_REP_TITLE, selectedActivityTab, selectedPerRepTab} = this.state;
            const clientFilterAllOption = [{label: 'All', value: ''}];
            const thisYear = new Date().getFullYear();
            const prevYear = thisYear - 1;
            const shopIsScroll = shop_by_invoices_data && shop_by_invoices_data.length > 25 ? true : false;
            const margin_sales_chart_data = {
              margin_by_client_data: margin_by_client_data,
              sales_by_client_data: sales_by_client_data
            };
            const { hasCapabilities } = getIdentityUtils(identity);
            const canEditTargets = hasCapabilities('MODIFY-SALES-TARGET');
            const originalStyle = {
                top: 0,
                left: 0,
                zIndex: 100,
                width: '100%',
                minHeight: '100%',
                background: 'rgb(237, 242, 245)',
                position: 'absolute',
                paddingBottom: '2em'
              };
              const repTypeOptions = [
                { value: 'CLIENT', label: 'Client Rep'},
                { value: 'ORDER', label: 'Order Rep'},
              ];
              let tabs=[
                {
                  label:'Sales by Client',
                  key:'Sales',
                }
              ];
              tabs.push({
                label:'Margin by Client',
                key:'Margin',
              });
              let perReptabs = [
                {
                label: 'SO in Production',
                key: 'SO'
                },
                {
                label:'Invoices',
                key:'Invoices'
                },
              ];
              let CRMSalesTabs = [
                {
                  label:'CRM Activity',
                  key:'CRM'
                },
                {
                  label:'Sales Activity',
                  key:'SalesActivity'
                }
              ];
            return (<div>
                <Theme>
                <GlobalStyle />
                <Header>
                    <div className="columns">
                    </div>
                </Header>
                <MainSection className="resku" popups={popups} style={{background:'rgb(237, 242, 245)'}}>
                  <div className="main-content csku-styles" style={{ ...originalStyle, top: this.state.top_margin, marginTop: 0 }}>
                  <Row style={{ marginTop: 0, marginBottom: 20, }}>
                    <Col xs sm={6} padded>
                    {this.renderPopup()}
                    {this.targetPopup()}
                      <DashboardPageTitle title={"Management Analytics"} />
                    </Col>
                    <Col xs
                      sm={6}
                      padded
                      end={1}
                    > {canEditTargets ? <Button variant="primary" style={{marginRight :5}} onClick={()=>{this.setState({showTargetPopup:{
                      title : 'Edit Targets'
                    }});}}>Edit Targets</Button>: null} <NewProjectPopupButton /></Col>

                  </Row>
                  <Row  style={{ placeContent: 'flex-start' }}>
                    <Col padded xl={3} sm={6} xs={8}>
                      <LabeledSelect
                        label="Rep Type"
                        name="repType"
                        options={repTypeOptions}
                        value={filters.repType}
                        onChange ={(e)=>this.onChangeFilter(e,'repType')}
                        className="csku-select-v3"
                      />
                    </Col>
                    <Col padded xl={3} sm={6} xs={8}>
                      <LabeledSelect
                        label="Rep"
                        name="repUser"
                        options={filterOptions.reps_all}
                        value={filters.repUser}
                        onChange={(e)=>this.onChangeFilter(e,'repUser')}
                        className="csku-select-v3"
                        isClearable = {!(filters.repUser.value === '')}
                    />
                    </Col>
                    <Col padded xl={3} sm={6} xs={8}>
                      <LabeledSelect
                        label="Client"
                        name="client"
                        value={filters.client}
                        options={clientFilterAllOption.concat(filterOptions.clients)}
                        onChange ={(e)=>this.onChangeFilter(e,'client')}
                      // onFocus={(e) => {e.target.select(),onChangeFilterDrop('client',e.target)}}
                        menuShouldScrollIntoView={false}
                        className="csku-select-v3"
                        isClearable =  {!(filters.client.value === '')}
                      />
                    </Col>
                    <Col padded xl={3} sm={6} xs={8}>
                      <LabeledSelect
                        label="Client Status"
                        name="clientStatus"
                        value={filters.clientStatus}
                        options={filterOptions.statuses_all}
                        onChange={(e)=>this.onChangeFilter(e,'clientStatus')}
                        menuShouldScrollIntoView={false}
                        className="csku-select-v3"
                        isClearable = {!(filters.clientStatus.value === '')}
                      />
                    </Col>
                    {filters.repUser.value === 'GROUP' ?
                    <Col padded xl={3} sm={6} xs={8}>
                      <LabeledSelect
                        name="teamId"
                        options={filterOptions.team_options}
                        value={filters.teamId}
                        onChange={(e)=>this.onChangeFilter(e,'teamId')}
                        label="Group"
                        menuShouldScrollIntoView={false}
                        className="csku-select-v3"
                        isClearable
                      />
                    </Col>
                    : null}
                  </Row>
                  {Object.keys(managemnt_data).length ? <>
                  <Row  style={{ placeContent: 'flex-start' }}>
                    <SalesOrdersInProductionChart
                    chart_data={filters.repType.value != 'ORDER' && identity.show_sales_order_targets == true ? sales_orders_target_data : sales_orders_data}
                    onClickChartColumn = {this.onClickSalesOrdersInProductionChartColumn}/>
                    <InvoicesVsTargetChart chart_data = {filters.repType.value != 'ORDER' && identity.show_invoice_targets == true ? invoice_target_data : invoice_data}
                    chart_height='340px'
                    onClickChartColumn = {this.onClickInvoiceTargetChartColumn}
                    />
                  </Row>
                  <Row style={{placeContent:'flex-start'}}>
                    <WidgetCardBox colSizes={{xs: 12, lg: 12}}
                    components={{Title:  <WidgetTitle />
                    }}>
                      <Col xs >
                        <Row style={{justifyContent:'space-between'}}>
                          <TabBar style={{ marginLeft: '1rem', marginBottom: '1rem' }}>
                          {perReptabs.map((tab, index) => <Tab
                          size={perReptabs.length}
                          selected={tab.key === this.state.selectedPerRepTab}
                          onClick={() => {this.setState({ selectedPerRepTab: tab.key });}}>
                          {tab.label}
                          </Tab>)}
                          </TabBar>
                          <Col padded xl={3} sm={6} xs={6}>
                            <LabeledSelect
                            name="SO_BY_REP_FILTER"
                            value={SO_BY_REP_FILTER}
                            options={sales_by_rep_time_options}
                            onChange ={(e)=>this.onChangeTimeFilter(e,'SO_BY_REP_FILTER')}
                            menuShouldScrollIntoView={false}
                            className="csku-select-v3"
                            />
                          </Col>
                        </Row>
                        <Row >
                          <Col xl={6} sm={12} >
                            <WidgetTitle startText={CHART_BY_REP_TITLE.current} style={{marginLeft:'2em'}}/>
                            <SalesOrdersPerRepChart data = {selectedPerRepTab === 'SO' ? sales_orders_per_rep_data.current : invoice_per_rep_data.current } barColor = {['#A6F4FF']}
                            onClickChartColumn = {this.onClickRepChartBar}
                            />
                          </Col>
                          <Col xl={6} sm={12} >
                            <WidgetTitle startText={CHART_BY_REP_TITLE.previous} style={{marginLeft:'2em'}}/>
                            <SalesOrdersPerRepChart data = { selectedPerRepTab === 'SO' ? sales_orders_per_rep_data.previous : invoice_per_rep_data.previous } barColor = {['#E1F7FA']}
                            onClickChartColumn = {this.onClickRepChartBar}
                            />
                            </Col>
                        </Row>
                      </Col>
                    </WidgetCardBox>
                  </Row>
                  <Row style={{placeContent:'flex-start'}}>
                    <WidgetCardBox colSizes={{xs: 12, lg: 12}}  components={{
                    Title: <WidgetTitle />
                    }}>
                      <Col xs >
                        <TabBar style={{ marginLeft: '1rem', marginBottom: '1rem' }}>
                        {tabs.map((tab, index) => <Tab
                        size={tabs.length}
                        selected={tab.key === this.state.selectedTab}
                        onClick={() => {this.setState({ selectedTab: tab.key });}}>
                        {tab.label}
                        </Tab>)}
                        </TabBar>
                        <SalesAndMarginByClient chart_data ={margin_sales_chart_data}
                        selectedTab = {selectedTab}
                        onClickClientSlice = {this.onClickClientSlice}
                        />
                      </Col>
                    </WidgetCardBox>
                  </Row>
                  <Row style={{placeContent:'flex-start',backgroundColor:'rgb(237, 242, 245)'}}>
                    <WidgetCardBox  colSizes={{xs: 12,sm:12, lg: 6}}
                    components={{
                    Title:<WidgetTitle/>
                    }}>
                     <Col xs >
                      <TabBar style={{ marginLeft: '1rem', marginBottom: '1rem' }}>
                      {CRMSalesTabs.map((tab, index) => <Tab
                      size={CRMSalesTabs.length}
                      selected={tab.key === selectedActivityTab}
                      onClick={() => {this.setState({ selectedActivityTab: tab.key });}}>
                      {tab.label}
                      </Tab>)}
                      </TabBar>
                      <CRMActivityChart chart_data={sales_activity_data}  selectedTab = {selectedActivityTab} />
                      <SalesActivityChart chart_data={sales_pipeline_data} onClickChartColumn={this.onClickSalesActivityChartColumn}
                       selectedTab = {selectedActivityTab}/>
                      </Col>

                  </WidgetCardBox>
                    <WidgetCardBox  colSizes={{xs: 12,sm:12, lg: 6}} components={{
                    Title:<WidgetTitle startText={'Invoices by Shop'}/>
                  }}>
                  <Col xs>
                    <Chart
                    type="Bar"
                    height="400px"
                    width="100%"
                    isExportable={false}
                    data = {shop_by_invoices_data}
                    onChartReady={(chart,data)=>createHorizontalBarChart(chart,{
                      data,
                      isExportable:false,
                      cursorLineX:false,
                      cursorLineY:false,
                      formatNumber:'#a',
                      xAxes: [{
                        cursorTooltipEnabled:false,
                        category:'shop_name',
                        // title: 'Shops',
                        showGrid: false,
                        showAxis: false,
                        showTitle: false,
                        showLabel: false,
                        disableGridTemplate:true,
                        //fontSize:13,
                        //labelWidth: 80,
                        // labelTooltipText:`{shop_name}`,
                        //truncate:true,
                        maxZoomCount:25,
                      }],
                      yAxes: [{
                        cursorTooltipEnabled: false,
                        title: 'Invoices in last 60 days',
                        showGrid: false,
                        showAxis: true,
                        showTitle: true,
                        disableGridTemplate:true,
                        showLabel: true,
                        isScrollbarY:true
                      }], series: [{
                        categoryX: 'shop_name',
                        valueY: 'invoice_total',
                        name: "Invoices",
                        valueYShow : "totalPercent",
                        tooltipText: `{shop_name}: {invoice_total}`,
                        strokeWidth: 0,
                        fillPropertyOpacity: 'opacity',
                        //fillDifferentColors: true,
                        fillColor:'#02C0DA',
                        stroke:'#02C0DA',
                        cornerRadius:8,
                        isScrollbarY:shopIsScroll,
                        // maxHeight:15,
                        //maxHeight2:70,
                        //CountHeightChange:6,
                        isInsideBarLabel:true,
                        insideBarLabelText:`{shop_name}`
                      }],

                    })}
                    >

                    </Chart>
                    </Col>
                    </WidgetCardBox>
                  </Row>
                    <div style={{display:'flex',  backgroundColor:'rgb(237, 242, 245)'}}>
                      <SalesActivityChart chart_data={sales_pipeline_data} onClickChartColumn={this.onClickSalesActivityChartColumn}/>
                    </div>

                  </>: <> <Loading />
                <p style={{
                  padding: 10,
                  margin: 0,
                  textAlign: 'center',
                  color: '#00A0B6',
                  fontSize: '1.3rem',
                }}>Loading...</p></>} </div>
                </MainSection>
                </Theme>
            </div>);
    }
}
const mapStateToProps = (state, ownProps) => {
    const invoice_data = state.entities.management_analytics.invoice_results;
    const target_data = state.entities.management_analytics.sales_target_results;
    const sales_orders_data = state.entities.management_analytics.in_production_results;
    return {
      identity: state.identity,
      filterOptions: {
        reps_all: getOptions(state.dropdowns.users, 'user_id', (v) => (v.contact_first_name + ' ' + v.contact_last_name), false, [{ label: 'All', value: '' },{label: 'Group',value:'GROUP'}]),
        statuses_all: getOptions(state.dropdowns.account_statuses, 'account_status_id', 'account_status_name', false, [{ label: 'All', value: '' }, { label: 'None', value: 'null' }]) ,
        clients:getClientOptions(state),
        team_options:getOptions(state.dropdowns.teams,'team_id','team_name',false,[{label:'All',value:''}] )
      },
      managemnt_data:state.entities.management_analytics,
      invoice_data:invoice_data,
      invoice_target_data: getCombinedTargetData(invoice_data,target_data),
      sales_by_client_data:  state.entities.management_analytics.client_sales_results,
      sales_orders_data:sales_orders_data,
      sales_orders_target_data: getCombinedTargetData(sales_orders_data,target_data),
      margin_by_client_data : state.entities.management_analytics.client_margin_results,
      sales_activity_data:state.entities.management_analytics.sales_activity_results,
      sales_pipeline_data: state.entities.management_analytics.sales_pipeline_results,
      shop_by_invoices_data:state.entities.management_analytics.shop_by_invoices_results,
      sales_orders_per_rep_data: state.entities.management_analytics.sales_orders_per_rep_results,
      invoice_per_rep_data: state.entities.management_analytics.invoices_by_rep_results

    };

  };
const mapDispatchToProps = dispatch => ({
  onLoadAccountStatuses:  (company_type = 'CLIENT') => {
    dispatch(createLoadAccountStatuses(company_type));
  },
  onLoadStatuses: () => {
    dispatch(createAllLoadStatuses({}));
  },
  onLoadClients: ()=> {
    dispatch(createLoadClientList({
      'order_by': 'latest_use',
      'order_dir': 'DESC',
  }));
},
  onLoadTeams: ()=> {
    dispatch(createLoadTeamList({team_type: 'GROUP'}));
  },
  onChangeFilterDrop: (filters) => {
      dispatch(updateMngmntAnalyticsView(filters));
    },
    onLoadAnalyticsData : () => {
      dispatch(fetchMngmntAnalytics());
    },
    fetchPopUpData :(route,type,obj) =>{
      dispatch(fetchAnalyticsReport(route, type, obj));
    },
    onChangeChartFilterMngmnt: (route,obj) => {
      dispatch(updateMngmntChart(route,obj));
    }
});

export default withReducers(connect(mapStateToProps,mapDispatchToProps)(ManagementAnalyticsApp),{
  status: statusReducer,
  notes: notesReducer
});
