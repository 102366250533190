import { Chart } from '../components/helpers';
import {createBarChart} from '../components/dashboard/utils/chart_utils';
import WidgetCardBox, { WidgetTitle } from '../components/dashboard/WidgetCardBox';
import {  Col, Row} from '@commonsku/styles';

function CRMActivityChart(props) {
    return(
        <Chart style = {{ display : props.selectedTab === 'CRM' ? 'block' : 'none'}}
        type="Bar"
        height="345px"
        width="100%"
        isExportable={false}
        data={props.chart_data}
        onChartReady={(chart,data)=>createBarChart(chart,{
        isExportable:false,
        data,
        cursorLineX:false,
        cursorLineY:false,
        isLegendCheckbox:true,
        formatNumber:'#a',
        chartColorsAnalytics:['#EB1D6E','#05476E','#5BBDEC'],
        xAxes: [{
            cursorTooltipEnabled:false,
            category:'time',
            title: 'Time',
            showGrid: false,
            showAxis: true,
            showTitle: true,
            showLabel: true,
            disableGridTemplate:true
        }],
        yAxes: [{
            cursorTooltipEnabled: false,
            showGrid: false,
            showAxis: false,
            showTitle: false,
            renderOutside: true,
            showLabel:true,
            disableGridTemplate:true
        }],
        series: [
            {
            categoryX: 'time',
            valueY: 'NOTE',
            name: "Notes",
            tooltipText: `{name}: {valueY}`,
            strokeWidth: 0,
            fillPropertyOpacity: 'opacity',
            fillColor : '#EB1D6E',
            stroke : '#EB1D6E',
            stacked:true
            },
            {
            categoryX: 'time',
            valueY: 'MEETING',
            name: "Meetings",
            valueYShow : "totalPercent",
            tooltipText: `{name}: {valueY}`,
            strokeWidth: 0,
            fillPropertyOpacity: 'opacity',
            fillColor : '#05476E',
            stroke : '#05476E',
            stacked:true
            },
            {
            categoryX: 'time',
            valueY: 'CALL',
            name: "Calls",
            tooltipText: `{name}: {valueY}`,
            strokeWidth: 0,
            fillPropertyOpacity: 'opacity',
            fillColor : '#5BBDEC',
            stroke : '#5BBDEC',
            stacked:true
        }]
        })}
        >
        </Chart>
    );
}

export default CRMActivityChart;